import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { Address } from 'src/app/modals/shop.modal';
import { LoginService } from 'src/app/services/login.service';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { SoulsUser } from 'src/app/modals/users.modal';
import { ChooseAreaComponent } from '../choose-area/choose-area.component';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss'],
})
export class EditAddressComponent implements OnInit {

  @Input() selectedAddress: Address;
  @Input() index: number;

  form: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private loadCtrl: LoadingController,
    private afs: AngularFirestore,
    private loginService: LoginService,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
    console.log(this.selectedAddress);
    console.log(this.index);

    this.form = new FormGroup({
      area: new FormControl(this.selectedAddress.area , {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      block: new FormControl(this.selectedAddress.block , {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      street: new FormControl(this.selectedAddress.street , {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      lane: new FormControl(this.selectedAddress.lane ? this.selectedAddress.lane : null, {
        updateOn: 'change'
      }),
      house: new FormControl(this.selectedAddress.house , {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      floor: new FormControl(this.selectedAddress.floor ? this.selectedAddress.floor : null , {
        updateOn: 'change'
      }),
      apartment: new FormControl(this.selectedAddress.apartment ? this.selectedAddress.apartment : null , {
        updateOn: 'change'
      })
    });

  }

  async onSave() {

    const loadEl = await this.loadCtrl.create();
    loadEl.present();

    const fieldPath = new firebase.firestore.FieldPath('address', this.index + '');

    try {

      const newAddress = this.loginService.loginUser.address;

      newAddress[this.index] = this.form.value;

      await this.afs.doc<SoulsUser>('clients/' + this.loginService.loginUser.uid).update({
        address: newAddress
      });

      loadEl.dismiss();

      this.modalCtrl.getTop().then(modalEl => {
        modalEl.dismiss();
      });
      

    } catch (e) {
      console.log(e);
      loadEl.dismiss();
      alert('error');
    }

   

  }

  onClose() {
    this.modalCtrl.getTop().then(modalEl => {
      modalEl.dismiss();
    });
  }

  showAreas() {
    this.popoverController.create({
      component: ChooseAreaComponent,
      keyboardClose: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: 'ion-popover-area'
    })
    .then(popEl => {
      popEl.present();
      return popEl.onWillDismiss();
    })
    .then(data => {

      if (data.role == 'select') {
        this.form.patchValue({
          area: data.data.selectedArea
        });
      }

    })
  }

}
