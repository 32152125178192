import { AnimationController, Animation } from '@ionic/angular'

export const enterAnimation = (baseEl: HTMLElement): Animation => {
    console.log(baseEl);
    
    const animationCtrl = new AnimationController;

    return animationCtrl.create()
    .addElement(baseEl.querySelector('.modal-wrapper'))
    .duration(1000)
    .fromTo('opacity', 1, 1)
    .fromTo('transform', 'translateY(0)', 'translateY(0)')
}


export const leaveAnimation = (baseEl: HTMLElement): Animation => {
    
    const animationCtrl = new AnimationController;

    return animationCtrl.create()
    .addElement(baseEl.querySelector('.modal-wrapper'))
    .duration(500)
    .easing('ease-in')
    .fromTo('opacity', '1', '0')
    // .fromTo('transform', 'translateY(0)', 'translateY(100%)')
    
}

export const lineAnimation = (baseEl: HTMLElement): Animation => {
    
    const animationCtrl = new AnimationController;

    animationCtrl.create()
    .addElement(baseEl)
    .duration(500)
    .easing('ease-out')
    .fromTo('height', '5px', '20px')
    // .fromTo('height', '5px', 'calc(80vw * 0.359)')
    
    return null;
}



