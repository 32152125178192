import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import {  } from '@angular/fire/auth-guard';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then( m => m.ShopPageModule)
  },
  {
    path: 'adoption-lost-found',
    loadChildren: () => import('./adoption-lost-found/adoption-lost-found.module').then( m => m.AdoptionLostFoundPageModule)
  },
  {
    path: 'adoption-lost-found/:mode',
    loadChildren: () => import('./adoption-lost-found/adoption-lost-found.module').then( m => m.AdoptionLostFoundPageModule)
  },
  {
    path: 'my-profile',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'grooming',
    loadChildren: () => import('./grooming/grooming.module').then( m => m.GroomingPageModule)
  },
  {
    path: 'my-appointments',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () => import('./my-appointments/my-appointments.module').then( m => m.MyAppointmentsPageModule)
  },
  {
    path: 'order-history',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () => import('./order-history/order-history.module').then( m => m.OrderHistoryPageModule)
  },
  {
    path: 'pet-view/:id',
    loadChildren: () => import('./pet-view/pet-view.module').then( m => m.PetViewPageModule)
  },
  {
    path: 'pet-view',
    loadChildren: () => import('./pet-view/pet-view.module').then( m => m.PetViewPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
