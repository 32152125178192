import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { Adoption, LostFound } from '../modals/adopt.modal';
import { ImageUpload, Pet } from '../modals/pets.modals';





@Injectable({
  providedIn: 'root'
})
export class PetsService {

  public adoptionDetails: Adoption;
  public lostFoundDetails: LostFound;

  public petsType_en: string[] = [];
  petTypes_ar: string[] = [];
  petsSubType_en: any;
  petsSubType_ar: any;
  petsSubType: any;

  public selectedMyAdoptions: Adoption;
  public selectedMyLostPets: LostFound;

  public selectedPetEdit: Pet;


  
  constructor(
    private afs: AngularFirestore
  ) { 
    this.getTypes();
  }



  getTypes() {

    this.afs.doc('options/petsType').valueChanges().pipe(take(1)).subscribe(petTypes => {
      this.petsSubType = petTypes;

      for(const type in petTypes as Object) {
        this.petsType_en.push(type);
      }

    });

  }

  async setTypes() {

    const test = {
      Dogs: {
          name_en: "Dogs_en",
          name_ar: "Dogs_ar",
          types: [
              {
                  type_en: "Hello1",
                  type_ar: "Hello2"
              },
              {
                  type_en: "Hello3",
                  type_ar: "Hello4"
              }
          ]
      },
      Cats: {
          name_en: "Cats_en",
          name_ar: "Cats_ar",
          types: [
              {
                  type_en: "Hello11",
                  type_ar: "Hello22"
              },
              {
                  type_en: "Hello33",
                  type_ar: "Hello44"
              }
          ]
      }
  }




    await this.afs.doc('options/petsType').set(test);
  }

  deleteAll(images: ImageUpload[]) {

    for (const image of images) {
      
      if(image.isNew !== undefined) {
        if (image.isNew == false) {
          continue;
        }
      }

      if (image.ref) {
        image.ref.delete().then(rubish => {
          console.log(rubish);
        });
      }
    }


  }

}
