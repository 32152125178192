import { createNgModule } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Adoption, LostFound } from '../modals/adopt.modal';
import { Pet } from '../modals/pets.modals';
import { Vendor } from '../modals/shop.modal';
import { SoulsUser } from '../modals/users.modal';
import { LangService } from './lang.service';
import { NotificationsService } from './notifications.service';
import { ShopService } from './shop.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  public loginUser: SoulsUser;
  public loginUser$ = new BehaviorSubject<boolean>(false);
  public appMenu$ = new BehaviorSubject<{title: string; url: string; icon: string; isHidden: boolean}[]>(
      [{
        title: this.langService.translate.instant('menu.home'),
        url: '/home',
        icon: '../assets/icon/home.svg',
        isHidden: false
      },
      {
        title: this.langService.translate.instant('menu.profile'),
        url: '/my-profile',
        icon: '../assets/icon/myprofile.svg',
        isHidden: true
      },
      {
        title: this.langService.translate.instant('menu.adoption'),
        url: '/adoption-lost-found/adoption',
        icon: '../assets/icon/adoption.svg',
        isHidden: false  
      },
      {
        title: this.langService.translate.instant('menu.lost'),
        url: '/adoption-lost-found/lost',
        icon: '../assets/icon/lost.svg',
        isHidden: false  
      },
      {
        title: this.langService.translate.instant('menu.shop'),
        url: '/shop',
        icon: '../assets/icon/shop.svg',
        isHidden: true
      },
      {
        title: this.langService.translate.instant('menu.history'),
        url: '/order-history',
        icon: '../assets/icon/shop.svg',
        isHidden: true
      },
      {
        title: this.langService.translate.instant('menu.grooming'),
        url: '/grooming',
        icon: '../assets/icon/grooming.svg',
        isHidden: true
      },
      {
        title: this.langService.translate.instant('menu.appoint'),
        url: '/my-appointments',
        icon: '../assets/icon/grooming.svg',
        isHidden: true
      }
    ]
  );

  public homeAdoption$ = new BehaviorSubject<Adoption[]>([]);
  public homeLost$ = new BehaviorSubject<LostFound[]>([]);

  public allVendors$ = new BehaviorSubject<Vendor[]>([]);

  public selectedIndex: number = 0;

  public triggerAnimation$ = new BehaviorSubject<boolean>(false);
  
  // lastMenu: {title: string; url: string; icon: string; isHidden: boolean}[];

  public myPets: Pet[] = [];

  public currentDeliveryCharge: number;

  constructor(
    private auth: AngularFireAuth,
    private afs: AngularFirestore,
    private langService: LangService,
    private notificationService: NotificationsService,
    private shopService: ShopService,
    private platform: Platform,
    private rc: AngularFireRemoteConfig
  ) 
  {    

    this.getPets();

    this.initSatte();

    this.platform.ready().then(() => {

      this.appMenu$.next([{
        title: this.langService.translate.instant('menu.home'),
        url: '/home',
        icon: '../assets/icon/home.svg',
        isHidden: false
      },
      {
        title: this.langService.translate.instant('menu.adoption'),
        url: '/adoption-lost-found/adoption',
        icon: '../assets/icon/adoption.svg',
        isHidden: false  
      },
      {
        title: this.langService.translate.instant('menu.lost'),
        url: '/adoption-lost-found/lost',
        icon: '../assets/icon/lost.svg',
        isHidden: false  
      },
      {
        title: this.langService.translate.instant('menu.shop'),
        url: '/shop',
        icon: '../assets/icon/shop.svg',
        isHidden: true
      },
      {
        title: this.langService.translate.instant('menu.history'),
        url: '/order-history',
        icon: '../assets/icon/shop.svg',
        isHidden: true
      },
      {
        title: this.langService.translate.instant('menu.grooming'),
        url: '/grooming',
        icon: '../assets/icon/grooming.svg',
        isHidden: true
      },
      {
        title: this.langService.translate.instant('menu.appoint'),
        url: '/my-appointments',
        icon: '../assets/icon/grooming.svg',
        isHidden: true
      },
      {
        title: this.langService.translate.instant('menu.profile'),
        url: '/my-profile',
        icon: '../assets/icon/myprofile.svg',
        isHidden: true
      }
    ]);


      this.menuList();
    })

    this.loadVendors();

    this.initHomePage();

    this.initRemoteConfig();
  }



  getPets() {

    this.loginUser$.subscribe(isAuth => {

      if (isAuth) {

        console.log(this.loginUser);
        

        this.afs.collection<Pet>('pets', ref => ref
        .where('petOwnerId', '==', this.loginUser.uid))
        .valueChanges({idField: 'id'})
        .subscribe(petsData => {

          this.myPets = petsData;          

        });

      } else {
        this.myPets = [];
      }

    });
  }

  initSatte() {

  
    this.auth.user.pipe(
      switchMap(userCred => {
        if (userCred == null) {
          return from(['']);
        } else {
          
          if (userCred.email == null) {    
                                
            return this.afs.collection<SoulsUser>('clients', ref => ref
            .where('mobile', '==', userCred.phoneNumber)).snapshotChanges().pipe(map(dd => {
              
              if (dd.length > 0) {
                const newUser: SoulsUser = dd[0].payload.doc.data();
  
                newUser.uid = dd[0].payload.doc.id;
  
                return newUser;
              }


            }))
          } else {
            
            return this.afs.doc<SoulsUser>('clients/' + userCred.uid).valueChanges().pipe(map(dd => {
              dd.uid = userCred.uid;
              return dd;
            }));
          }
        }
      })
    ).subscribe(userData => {

      
      if (userData == '') {
        this.shopService.cart$.next([]);
        this.loginUser = undefined;
        this.myPets = [];
        this.loginUser$.next(false);
      } else {
              
        this.loginUser = userData as SoulsUser;
        this.loginUser$.next(true);
        this.shopService.cart$.next(this.loginUser.cart);        
        this.notificationService.requestPermession(this.loginUser.fcmTokens, this.loginUser.uid);
        
      }
    });

  }

  menuList() {

    this.loginUser$.subscribe(isAuth => {
      
      if (isAuth) {

        this.appMenu$.next([{
          title: this.langService.translate.instant('menu.home'),
          url: '/home',
          icon: '../assets/icon/home.svg',
          isHidden: false
        },
        {
          title: this.langService.translate.instant('menu.profile'),
          url: '/my-profile',
          icon: '../assets/icon/myprofile.svg',
          isHidden: false
        },
        {
          title: this.langService.translate.instant('menu.adoption'),
          url: '/adoption-lost-found/adoption',
          icon: '../assets/icon/adoption.svg',
          isHidden: false  
        },
        {
          title: this.langService.translate.instant('menu.lost'),
          url: '/adoption-lost-found/lost',
          icon: '../assets/icon/lost.svg',
          isHidden: false  
        },
        {
          title: this.langService.translate.instant('menu.shop'),
          url: '/shop',
          icon: '../assets/icon/shop.svg',
          isHidden: false
        },
        {
          title: this.langService.translate.instant('menu.history'),
          url: '/order-history',
          icon: '../assets/icon/shop.svg',
          isHidden: false
        },
        {
          title: this.langService.translate.instant('menu.grooming'),
          url: '/grooming',
          icon: '../assets/icon/grooming.svg',
          isHidden: false
        },
        {
          title: this.langService.translate.instant('menu.appoint'),
          url: '/my-appointments',
          icon: '../assets/icon/grooming.svg',
          isHidden: false
        }
      ]);
        
      } else {              
  
        this.appMenu$.next([{
          title: this.langService.translate.instant('menu.home'),
          url: '/home',
          icon: '../assets/icon/home.svg',
          isHidden: false
        },
        {
          title: this.langService.translate.instant('menu.profile'),
          url: '/my-profile',
          icon: '../assets/icon/myprofile.svg',
          isHidden: true
        },
        {
          title: this.langService.translate.instant('menu.adoption'),
          url: '/adoption-lost-found/adoption',
          icon: '../assets/icon/adoption.svg',
          isHidden: false  
        },
        {
          title: this.langService.translate.instant('menu.lost'),
          url: '/adoption-lost-found/lost',
          icon: '../assets/icon/lost.svg',
          isHidden: false  
        },
        {
          title: this.langService.translate.instant('menu.shop'),
          url: '/shop',
          icon: '../assets/icon/shop.svg',
          isHidden: false
        },
        {
          title: this.langService.translate.instant('menu.history'),
          url: '/order-history',
          icon: '../assets/icon/shop.svg',
          isHidden: true
        },
        {
          title: this.langService.translate.instant('menu.grooming'),
          url: '/grooming',
          icon: '../assets/icon/grooming.svg',
          isHidden: false
        },
        {
          title: this.langService.translate.instant('menu.appoint'),
          url: '/my-appointments',
          icon: '../assets/icon/grooming.svg',
          isHidden: true
        }
      ]);
      
      }

    });


  }

  loadVendors() {

    this.afs.collection<Vendor>('vendors', ref => ref.where('isActive', '==', true)).get().subscribe(vendors => {
      
      const allVendors = vendors.docs.map(item => {
        return {...item.data(), id: item.id} as Vendor;
      });

      this.allVendors$.next(allVendors);

    });

  }

  logout() {
    this.auth.signOut();
  }

  initHomePage() {

    this.afs.collection<Adoption>('adoption', ref => ref
    .where('isClosed', '==', false)
    .orderBy('dateAdded', 'desc')
    .limit(5))
    .get()
    .subscribe(adoption => {
      const tempAdoption = adoption.docs.map(item => {
        return {...item.data(), id: item.id} as Adoption
      });
      this.homeAdoption$.next(tempAdoption);
    });


    this.afs.collection<Adoption>('lostfound', ref => ref
    .where('isClosed', '==', false)
    .orderBy('dateAdded', 'desc')
    .limit(5))
    .get()
    .subscribe(lost => {
      const tempLost = lost.docs.map(item => {
        return {...item.data(), id: item.id} as LostFound
      })
      this.homeLost$.next(tempLost);
    })


  }

  async initRemoteConfig() {
    
        
    await this.rc.fetchAndActivate();

    this.rc.getString('delivery').then(async advertiseString => {
      
      if (advertiseString == 'none') {
        this.rc.fetch();
        return;
      }      
      
      this.currentDeliveryCharge = +advertiseString;

    })

  }



}
