import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ImageUpload, Pet } from 'src/app/modals/pets.modals';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { PetsService } from 'src/app/services/pets.service';
import { PetsCategoryComponent } from '../pets-category/pets-category.component';
import { LoginService } from 'src/app/services/login.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { LangService } from 'src/app/services/lang.service';


@Component({
  selector: 'app-add-pet',
  templateUrl: './add-pet.component.html',
  styleUrls: ['./add-pet.component.scss'],
})
export class AddPetComponent implements OnInit {

  form: FormGroup;

  isNoPhoto: boolean = true;

  imagesUpload: ImageUpload[] = [];
  uploadTasks: AngularFireUploadTask[] = [];
  isUploading: boolean = false;

  isMicroOk: boolean = false;
  reType: string = null;

  newDocRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

  acceptedFormats = ['jpg', 'jpeg', 'png', 'gif'];

  subs: Subscription;

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    private loadCtrl: LoadingController,
    private modalCtrl: ModalController,
    public petService: PetsService,
    private loginService: LoginService,
    private fns: AngularFireFunctions,
    private alertCtrl: AlertController,
    private langService: LangService
  ) { }

  ngOnInit() {

    this.modalCtrl.getTop().then(modalEl => {
      modalEl.onWillDismiss().then(data => {
        if (data.role !== "done") {          
          this.petService.deleteAll(this.imagesUpload);
        }
      })
    });

    this.newDocRef = firebase.firestore().collection('pets').doc();    

    this.imagesUpload.push({
      Uploaded: true,
      imageUrl: '../../../assets/addhere.svg',
      errorMessege: '',
      isDeleting: false,
      isError: false,
      isPaused: false,
      isUploading: false,
      name: '',
      path: 'firstSouls'
    });
  

    this.form = new FormGroup({
      name: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.maxLength(50), Validators.minLength(1)]
      }),
      type: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      }),
      subtype: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      }),
      gender: new FormControl('male', {
        updateOn: "change",
        validators: [Validators.required]
      }),
      birthday: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      }),
      weight: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.max(600),  Validators.min(0.0001)]
      }),
      medicalCondition: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.maxLength(60)]
      }),
      behaviors: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.maxLength(60)]
      }),
      description: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.maxLength(500)]
      }),
      microchip: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      })
    });

    this.subs = this.form.get('type').valueChanges.subscribe(ss => {
      this.form.get('subtype').reset();
    });


  }

  onClose() {
    this.modalCtrl.getTop().then(modalEl => {
      modalEl.dismiss();
    });
  }

  validate(reMicrochip: string) {
    if (this.form) {
      if (this.form.contains('microchip')) {
        this.reType = reMicrochip;
        if (this.form.get('microchip').value == reMicrochip) {
          this.isMicroOk = true;
        } else {
          this.isMicroOk = false;
        }
      }
    }
  }

  validate2() {
    if (this.form) {
      if (this.form.contains('microchip')) {
        if (this.form.get('microchip').value == this.reType) {
          this.isMicroOk = true;
        } else {
          this.isMicroOk = false;
        }
      }
    }
  }
  

  openExplorer() {
    let element: HTMLElement = document.getElementById('fileElement');
    element.click();
  }

  uploadToFirebase(event) {

    if (event.target.files.length == 0) {
      return
    }

    if (event.target.files == undefined) {
      return
    }

    if (event.target.files == null) {
      return;
    }

    this.isUploading = true;

    const imageSelected: FileList = event.target.files;

    // validate -------------------------------------------------

    let index = 0;

    let isValid: boolean = true;

    while (index < imageSelected.length) {
      
      const isAccepted: number = this.acceptedFormats.findIndex(extension => {
        const explode = imageSelected.item(index).name.split('.');
        const extent = explode[explode.length - 1].toLowerCase();
        return extension == extent;
      });

      if (isAccepted == -1) {
        isValid = false;
      }
      index++;
    }


    if (isValid == false) {
      alert('Some images are not in a valid format. Please, try again');
      this.isUploading = false;
      return;
    }


    // ---------------- Upload ----------------

    

    index = 0;
    
    const tempUploadData$: Observable<UploadTaskSnapshot>[] = [];

    while (index < imageSelected.length) {


      const randomNumber: number = firebase.firestore.Timestamp.now().seconds*(Math.random()*10);

      const splited: string[] = imageSelected.item(index).name.split('.');
      
      const newImage: ImageUpload = {
        name: imageSelected.item(index).name,
        imageUrl: '',
        Uploaded: false,
        errorMessege: '',
        isError: false,
        isUploading: true,
        isPaused: false,
        isDeleting: false,
        ref: null,
        path: 'petsImages/' + this.newDocRef.id + '/' + randomNumber + '.' + splited[splited.length - 1]
      }

  
      this.imagesUpload.push(newImage);
      
      // Make the observable

      tempUploadData$.push(this.storage.upload(newImage.path, imageSelected.item(index)).snapshotChanges());

      index++;
    }

    combineLatest(tempUploadData$).subscribe(data => {
      
      index = this.imagesUpload.length - data.length;

      let index2 = 0;

      let allIsUploaded: boolean = true;
            
      while (index < this.imagesUpload.length) {

        console.log(this.imagesUpload.length);        
        console.log(index);
        console.log(index2);
        
        if (data[index2].state == 'success') {
          this.imagesUpload[index].isUploading = false
          this.imagesUpload[index].Uploaded = true;
          this.imagesUpload[index].ref = data[index2].ref;
          this.getURL(data[index2].ref);
        }

        if (data[index2].state == 'running') {
          this.imagesUpload[index].isUploading = true
          this.imagesUpload[index].Uploaded = false;
          allIsUploaded = false;
        }

        if (data[index2].state == 'error') {
          this.imagesUpload[index].isUploading = false
          this.imagesUpload[index].Uploaded = false;
          this.imagesUpload[index].isError = true;
        }

        index2++
        index++;
      }

      if (allIsUploaded) {
        this.isUploading = false;
      }

    })
  }

  async getURL(ref: firebase.storage.Reference) {

    const index = this.imagesUpload.findIndex(item => {
      return item.ref == ref;
    });

    this.imagesUpload[index].ref = ref;

    const url = await ref.getDownloadURL();
    this.imagesUpload[index].imageUrl = url;

    if (this.isNoPhoto) {
      this.isNoPhoto = false;
      this.imagesUpload.splice(0, 1);
    }

  }

  viewImage(index: number) {
    window.open(this.imagesUpload[index].imageUrl, "_blank");
  }

  async deleteImage(ref: firebase.storage.Reference) {

    const index = this.imagesUpload.findIndex(item => {
      return item.ref == ref;
    });
    
    this.imagesUpload[index].isDeleting = true;

    try {
      await ref.delete();
      this.imagesUpload.splice(index, 1);
    } catch (e) {
      this.imagesUpload[index].isDeleting = false;
    }

    if (this.imagesUpload.length == 0) {
      this.imagesUpload.push({
        Uploaded: true,
        imageUrl: '../../../assets/addhere.svg',
        errorMessege: '',
        isDeleting: false,
        isError: false,
        isPaused: false,
        isUploading: false,
        name: '',
        path: 'firstSouls'
      });

      this.isNoPhoto = true;
    }



  }

  async onSubmit() {
    
    if (!this.form.valid) {
      alert('Please fill All Required Fields Correctly');
      return;
    }    
  
    if(this.imagesUpload[0].path == 'firstSouls') {
      alert('You have to Upload at least one photo.');
      return;
    }
    

    if (!this.isMicroOk) {
      alert('Retype the Microchip Correctly.');
      return;
    }

    const loadEl = await this.loadCtrl.create();
    loadEl.present();
    
    const newPet: Pet = {
      ...this.form.value,
      petOwnerId: this.loginService.loginUser.uid,
      noOwner: true,
      isAdoption: false,
      isLost: false,
      ownersHistoryIds: [],
      dateAdded: new Date().toISOString(),
      lastModified: new Date().toISOString(),
      imageUrls: []
    }

    const isDuplicateFunction = this.fns.httpsCallable<{microchip: string}, boolean>('isDuplicateMicrochip');

    let isDuplicate: boolean;

    try {
      isDuplicate = await isDuplicateFunction({microchip: newPet.microchip}).toPromise();

      if (isDuplicate) {
        const alertEl = await this.alertCtrl.create({
          cssClass: 'alert-main',
          header: 'Error',
          message: 'The microchip number you entered already exists in our database.',
          buttons: [{text: 'OK'}]
        });

        loadEl.dismiss();
        alertEl.present();
        return;
      }

    } catch (e) {
      const alertEl = await this.alertCtrl.create({
        cssClass: 'alert-main',
        header: 'Error',
        message: 'An error has occured please try later.',
        buttons: [{text: 'OK'}]
      });

      loadEl.dismiss();
      alertEl.present();
      console.log(e);
      return;
    }

    delete newPet['retypeMicrochip'];

    newPet.birthday = newPet.birthday.split('T')[0];

    let index = 0;

    while (index < this.imagesUpload.length) {

      if (this.imagesUpload[index].imageUrl == '') {
        continue;
      }

      newPet.imageUrls.push(this.imagesUpload[index].imageUrl);

      index++;
    }


    try {
      await this.newDocRef.set(newPet);
      loadEl.dismiss();
      this.modalCtrl.getTop().then(modalEl => {
        modalEl.dismiss('', 'done');
      });
    } catch (e) {
      console.log(e);
      loadEl.dismiss();
      alert('An error has occured. please, try again');
    }



  }

  showSubTypes(type: string) {

    console.log(this.langService.translate.currentLang);
    

    this.modalCtrl.create({
      component: PetsCategoryComponent,
      componentProps: {lang: this.langService.translate.currentLang, type: type}
    }).then(modalEl => {
      modalEl.present();
      return modalEl.onWillDismiss();
    }).then(data => {
      
      if (data.role == "select") {
        this.form.get('subtype').setValue(data.data.selectedCategory);
      }

    });
  }

  firstPhoto() {
    if (!this.isNoPhoto) {
      return;
    }

    this.openExplorer();

  }

  ionViewDidLeave() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

}
