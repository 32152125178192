import { Component, OnInit } from '@angular/core';

import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoginService } from './services/login.service';
import { LoginComponent } from './shared/login/login.component';
import { SignUpComponent } from './shared/sign-up/sign-up.component';
import { LangService } from './services/lang.service';
import { Router } from '@angular/router';
import { NotificationsService } from './services/notifications.service';
import { SplashScreenComponent } from './shared/splash-screen/splash-screen.component';
import { enterAnimation, leaveAnimation } from './animations/splash-animation';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public appPages: any[] = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public loginService: LoginService,
    private modalCtrl: ModalController,
    public langService: LangService,
    private loadCtrl: LoadingController,
    private notificationService: NotificationsService,
    private fns: AngularFireFunctions
  ) {
    
    if (localStorage.getItem('language') !== null) {
      if (localStorage.getItem('language') == 'ar') {
        document.dir = 'rtl';
      }
    }
    this.initializeApp();

    // this.fns.useFunctionsEmulator('http://localhost:5001');
    
  }

  initializeApp() {
    this.platform.ready().then(() => {

      if (this.platform.is('mobile')) {        
        if(this.platform.is('tablet')) {
          this.loginService.triggerAnimation$.next(true);
        } else {
  
          this.modalCtrl.create({
            component: SplashScreenComponent,
            mode: 'ios',
            enterAnimation: enterAnimation,
            leaveAnimation: leaveAnimation
          }).then(modalEl => {
            modalEl.present();
            return modalEl.onDidDismiss();
          })
          .then(data => {
            this.loginService.triggerAnimation$.next(true);
          });
        }

      } else {
        this.loginService.triggerAnimation$.next(true);        
      }


      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.langService.initLang();
      this.loginService.appMenu$.subscribe(menu => {
        this.appPages = menu;

        const path = window.location.pathname.split('/')[1];
        if (path !== undefined) {
          this.loginService.selectedIndex = this.appPages.findIndex(page => {

            if (path.startsWith('adoption-lost-found')) {
              const path2 = window.location.pathname.split('/')[2];          
              return (page.url.split('/')[2]) == path2;
            } else {
              return ('/' + page.url.split('/')[1]) == ('/' + path.split('/')[0]);
            }
            
          });
        }

      });
    });
  }

  ngOnInit() {
  }

  showLogin() {
    this.modalCtrl.create({
      component: LoginComponent,
      mode: 'ios'
    }).then(modalEl => {
      modalEl.present();
    });
  }

  showSignup() {
    this.modalCtrl.create({
      component: SignUpComponent,
      mode: 'ios'
    }).then(modalEl => {
      modalEl.present();
    });
  }

  async changeLang() {

    
    if (this.langService.translate.currentLang == 'en') {
      // this.langService.setLanguage('ar');
      localStorage.setItem('language', 'ar');
      const loadEl = await this.loadCtrl.create({mode: 'ios'});
      loadEl.present();
      setTimeout(() => {
        window.location.reload();
      }, 500);
      // document.styleSheets.item(0).addRule(':root', '--ion-font-family: var(--ion-font-family-ar)');
    } else {
      // this.langService.setLanguage('en');
      localStorage.setItem('language', 'en');
      const loadEl = await this.loadCtrl.create({mode: 'ios'});
      loadEl.present();
      setTimeout(() => {
        window.location.reload();
      }, 500);
      // document.styleSheets.item(0).addRule(':root', '--ion-font-family: var(--ion-font-family-en)');
    }
  }


}
