import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { PetsService } from 'src/app/services/pets.service';

@Component({
  selector: 'app-pets-category',
  templateUrl: './pets-category.component.html',
  styleUrls: ['./pets-category.component.scss'],
})
export class PetsCategoryComponent implements OnInit {


  @Input('lang') lang: string; 
  @Input('type') type: string;

  allTypes: any[] = [];

  groupedContacts = [];


  constructor(
    private modalCtrl: ModalController,
    private petsService: PetsService
  ) { }

  ngOnInit() {

    if (this.lang == "en") {

      for(const type of this.petsService.petsSubType[this.type].types) {
        this.allTypes.push(type['type_en']);
      }

      this.allTypes.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      
      this.groupContacts(this.allTypes);


    } else {
      for(const type of this.petsService.petsSubType[this.type].types) {
        this.allTypes.push(type['type_ar']);
      }

      this.allTypes.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });

      this.groupContacts(this.allTypes);
    }
    
  }

  onClose() {
    this.modalCtrl.getTop().then(modalEl => {
      modalEl.dismiss(null, 'cancel');
    });
  }

  selectCat(selected: string) {

    this.modalCtrl.getTop().then(modalEl => {
      modalEl.dismiss({selectedCategory: selected}, 'select');
    });
  }

  groupContacts(contacts){

    let sortedContacts = contacts.sort();
    let currentLetter = false;
    let currentContacts = [];

    sortedContacts.forEach((value, index) => {

        if(value.charAt(0) != currentLetter){

            currentLetter = value.charAt(0);

            let newGroup = {
                letter: currentLetter,
                contacts: []
            };

            currentContacts = newGroup.contacts;
            this.groupedContacts.push(newGroup);

        }

        currentContacts.push(value);

    });

}


}
