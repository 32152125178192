import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { LoginService } from 'src/app/services/login.service';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { ChooseAreaComponent } from '../choose-area/choose-area.component';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
})
export class AddAddressComponent implements OnInit {

  form: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private loadCtrl: LoadingController,
    private afs: AngularFirestore,
    private loginService: LoginService,
    private popoverController: PopoverController,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {

    this.form = new FormGroup({
      area: new FormControl(null , {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      block: new FormControl(null , {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      street: new FormControl(null , {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      lane: new FormControl(null , {
        updateOn: 'change'
      }),
      house: new FormControl(null , {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      floor: new FormControl(null , {
        updateOn: 'change'
      }),
      apartment: new FormControl(null , {
        updateOn: 'change'
      })
    });

  }

  onClose() {
    this.modalCtrl.getTop().then(modalEl => {
      modalEl.dismiss();
    });
  }

  async onSave() {

    if (!this.form.valid) {
      const alertEl = await this.alertCtrl.create({
        header: "Opps...",
        message: "Please Fill out all required fields",
        buttons: [{
          text: 'Ok',
          role: "cancel"
        }]
      });

      alertEl.present();

      return;
    }

    const loadEl = await this.loadCtrl.create();
    loadEl.present();

    if (this.loginService.loginUser$.value) {
      try {

        await this.afs.doc('clients/' + this.loginService.loginUser.uid).update({
          address: firebase.firestore.FieldValue.arrayUnion(this.form.value)
        });
  
        loadEl.dismiss();
  
        this.modalCtrl.getTop().then(modalEl => {
          modalEl.dismiss();
        });
        
  
      } catch (e) {
        console.log(e);
        loadEl.dismiss();
        alert('error');
      }
    } else {
      try {
        
        loadEl.dismiss();
  
        this.modalCtrl.getTop().then(modalEl => {
          modalEl.dismiss(this.form.value, 'noLogin');
        });
        
  
      } catch (e) {
        console.log(e);
        loadEl.dismiss();
        alert('error');
      }
    }
   

   

  }

  showAreas() {
    this.popoverController.create({
      component: ChooseAreaComponent,
      keyboardClose: true,
      mode: 'ios',
      backdropDismiss: true,
      cssClass: 'ion-popover-area'
    })
    .then(popEl => {
      popEl.present();
      return popEl.onWillDismiss();
    })
    .then(data => {

      if (data.role == 'select') {
        this.form.patchValue({
          area: data.data.selectedArea
        });
      }

    })
  }

}
