import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { take } from 'rxjs/operators';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { LoginService } from 'src/app/services/login.service';
import { SoulsUser } from 'src/app/modals/users.modal';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { uptime } from 'process';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  form2: FormGroup;

  isEmailMode: boolean = true;

  isOTPMode: boolean = false;

  conformation: firebase.auth.ConfirmationResult;

  loadEl: HTMLIonLoadingElement;

  constructor(
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private auth: AngularFireAuth,
    private loadCtrl: LoadingController,
    private alertCtrl: AlertController,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      })
    });
    
    this.form2 = new FormGroup({
      country: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      }),
      number: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      })
    });

    this.loadCtrl.create().then(loadEl => {
      this.loadEl = loadEl;
    });

  }

  onClose() {
    this.modalCtrl.getTop().then(modalEl => {
      modalEl.dismiss();
    });
  }

  async login() {
    if (this.form.valid) {

      this.loadEl.present()

      try {

        const credentials = await this.auth.signInWithEmailAndPassword(this.form.value.email, this.form.value.password);
        
        try {

          const userDetails = await this.afs.doc<SoulsUser>('clients/' + credentials.user.uid).valueChanges().pipe(take(1)).toPromise();
          userDetails.uid = credentials.user.uid;
          this.loginService.loginUser = userDetails;

          this.loadEl.dismiss();
          this.loadEl = await this.loadCtrl.create();
          this.modalCtrl.getTop().then(modalEl => {
            modalEl.dismiss();
          });

        } catch (e) {

          this.loadEl.dismiss();
          this.loadEl = await this.loadCtrl.create();
          alert('error getting database -email pass');

        }

      } catch (e) {
        console.log(e.code);
        // "auth/user-not-found"
        // 
        //
        //
        //
        //
        //
        //

        console.log(e);

        let messege: string = 'Error while logging in, please try again.';
        // "auth/user-not-found"
        switch (e.code) {
          case 'auth/user-not-found':
            messege = "This account doesn't exists, please sign up";
            break;
          case 'auth/wrong-password':
            messege = "Incorrect Password";
            break;
        }
        

        const alertEl = await this.alertCtrl.create({
          cssClass: 'alert-main',
          mode: 'ios',
          header: 'Error',
          message: messege,
          buttons: [{text: 'OK'}]
        });
        alertEl.present();
        this.loadEl.dismiss();
        this.loadEl = await this.loadCtrl.create();


      }


    }

    if (this.form2.valid) {

      let isPhoneExist: Boolean = true;
      
      this.loadEl.present();


      try {

        const data = await this.afs.collection('clients', ref => ref
        .where('mobile', '==', '+' + this.form2.value.country + this.form2.value.number))
        .valueChanges().pipe(take(1)).toPromise();

        if (data.length == 0) {

          isPhoneExist = false;

          const alertEl = await this.alertCtrl.create({
            cssClass: 'alert-main',
            mode: 'ios',
            header: 'Error',
            message: "Number Don't Exists",
            buttons: [{text: 'OK'}]
          });
          alertEl.present();
          this.loadEl.dismiss();
          this.loadEl = await this.loadCtrl.create();
          return;
        }
      } catch (e) {

        const alertEl = await this.alertCtrl.create({
          cssClass: 'alert-main',
          mode: 'ios',
          message: "Error",
          buttons: [{text: 'OK'}]
        });
        alertEl.present();
        this.loadEl.dismiss();
        this.loadEl = await this.loadCtrl.create();
        return;
      }


      let recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible'
      });
      

      this.loadEl.dismiss();
      this.loadEl = await this.loadCtrl.create();

      this.isOTPMode = true;

      
      
      this.conformation = await this.auth.signInWithPhoneNumber('+' + this.form2.value.country + this.form2.value.number, recaptchaVerifier);
        



    }
    
  }

  async signInPhone(OTP: string) {

    this.loadEl.present();
    
    
    try {

      const credentials = await this.conformation.confirm(OTP);
      this.loadEl.dismiss();
      this.loadEl = await this.loadCtrl.create();
      this.modalCtrl.getTop().then(modalEl =>{
        modalEl.dismiss();
      });
      
    } catch (e) {
      console.log(e);

      if (e.code == "auth/invalid-verification-code") {

        const alertEl = await this.alertCtrl.create({
          cssClass: 'alert-main',
          mode: 'ios',
          header: 'Error',
          message: "Wrong OTP Number. please try again.",
          buttons: [
            {
              text: 'OK'
            }
          ]
        });
        alertEl.present();
        this.loadEl.dismiss();
        this.loadEl = await this.loadCtrl.create();
        return;

      } else {

        const alertEl = await this.alertCtrl.create({
          cssClass: 'alert-main',
          mode: 'ios',
          header: 'Error',
          message: "An error has occured. please try again.",
          buttons: [
            {
              text: 'OK'
            }
          ]
        });
        alertEl.present();
        this.loadEl.dismiss();
        this.loadEl = await this.loadCtrl.create();
        return;
        
      }
    }

        // alert('error - creating database profile');
        // console.log(e);
        // LoadEl.dismiss();
      
  }

  switchMode() {

    this.form.reset();
    this.form2.reset();

    this.isEmailMode = !this.isEmailMode;

  }

  async onSignUp() {
    const modalElCurrent = await this.modalCtrl.getTop();
    const modalEl = await this.modalCtrl.create({
      component: SignUpComponent,
      mode: 'ios',
      cssClass: 'ion-modal-signup'
    });

    modalEl.present();
    modalElCurrent.dismiss();

  }

  async onForgot() {
    const modalElCurrent = await this.modalCtrl.getTop();
    const modalEl = await this.modalCtrl.create({
      component: ForgotPasswordComponent,
      mode: 'ios',
      cssClass: 'ion-modal-login'
    });

    modalEl.present();
    modalElCurrent.dismiss();

  }




}
