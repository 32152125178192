import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'app-choose-area',
  templateUrl: './choose-area.component.html',
  styleUrls: ['./choose-area.component.scss'],
})
export class ChooseAreaComponent implements OnInit {

  constructor(
    public shopService: ShopService,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  selectArea(selectedArea: string) {
    console.log(selectedArea);
    this.popoverController.dismiss({selectedArea: selectedArea}, 'select');
  }

}
