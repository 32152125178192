import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { take } from 'rxjs/operators';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { LoginService } from 'src/app/services/login.service';
import { SoulsUser } from 'src/app/modals/users.modal';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private auth: AngularFireAuth,
    private loadCtrl: LoadingController,
    private alertCtrl: AlertController,
    private loginService: LoginService
  ) { }

  ngOnInit() {

    this.form = new FormGroup({
      email: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.email]
      })
    });
    
  }

  onClose() {
    this.modalCtrl.getTop().then(modalEl => {
      modalEl.dismiss();
    });
  }


  async onSend() {

    const loadEl = await this.loadCtrl.create();
    loadEl.present();

    try {

      await this.auth.sendPasswordResetEmail(this.form.value.email)
      
      const alertEl = await this.alertCtrl.create({
        cssClass: 'alert-main',
        mode: 'ios',
        header: 'Sent',
        message: 'A password reset link has been sent to your email. Please check your junk emails folder also.',
        buttons: [{text: 'OK'}]
      });
      alertEl.present();
      loadEl.dismiss();
      alertEl.onDidDismiss().then(async data => {
        const modalEl = await this.modalCtrl.getTop();
        modalEl.dismiss();
      })

    } catch (e) {

      console.log(e);

      if (e.code == 'auth/user-not-found') {

        const alertEl = await this.alertCtrl.create({
          cssClass: 'alert-main',
          mode: 'ios',
          header: 'Error',
          message: 'This email doent exists !',
          buttons: [{text: 'OK'}]
        });
        alertEl.present();
        loadEl.dismiss();
         
      } else {
        const alertEl = await this.alertCtrl.create({
          cssClass: 'alert-main',
          mode: 'ios',
          header: 'Error',
          message: 'An Error has occured. Please try again',
          buttons: [{text: 'OK'}]
        });
        alertEl.present();
        loadEl.dismiss();

      }
      
    }

  }

}
