import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { lineAnimation } from '../../animations/splash-animation';

import { AnimationController, Animation } from '@ionic/angular'


@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    
  ) { }

  async ngOnInit() {

    document.getElementById('container').dir = 'ltr';

    const animationCtrl = new AnimationController;

    // const lineA =  lineAnimation(document.querySelector('.line'));

    const lineA = animationCtrl.create()
    .addElement(document.querySelector('.line'))
    .duration(1000)
    .delay(1000)
    .easing('ease-out')
    .fromTo('transform', 'scaleY(1)', 'scaleY(25)')


    const lineB = animationCtrl.create()
    .addElement(document.querySelector('.line'))
    .duration(700)
    .easing('ease-out')
    .delay(150)
    .fromTo('transform', 'translateX(0) scaleY(25)', 'translateX(80vw) scaleY(25)')

    const HideB = animationCtrl.create()
    .addElement(document.querySelector('.logo-hide'))
    .duration(700)
    .easing('ease-out')
    .delay(150)
    // .fromTo('backgroundColor', 'red', 'blue')
    // .fromTo('backgroundImage', 'linear-gradient(to right, transparent, transparent 100%, var(--ion-color-primary) 0%, var(--ion-color-primary))', 'linear-gradient(to right, transparent, transparent 0%, var(--ion-color-primary) 100%, var(--ion-color-primary))')
    // .fromTo('backgroundImage', 'linear-gradient(to right, transparent, transparent 100%, red 0%, red)', 'linear-gradient(to right, transparent, transparent 0%, red 100%, red)')
    // .fromTo('backgroundImage', 'linear-gradient(to right, transparent, transparent 0%, red 0%, red)', 'linear-gradient(to right, transparent, transparent 100%, red 100%, red)')
    .fromTo('transform', 'scaleX(1) translate(-50%, -50%)', 'scaleX(0) translate(-50%, -50%)')



    const lineC = animationCtrl.create()
    .addElement(document.querySelector('.line'))
    .duration(500)
    .delay(100)
    .easing('ease-out')
    .fromTo('transform', 'scaleY(25) translateX(80vw)', 'scaleY(1) translateX(80vw)')

    
    await lineA.play();

    await Promise.all([lineB.play(), HideB.play()]);

    await lineC.play();


    setTimeout(() => {
      this.modalController.dismiss();
    }, 800);
    
  }

}
