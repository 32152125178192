import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire'
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS } from '@angular/fire/remote-config';



import { environment } from 'src/environments/environment';
import { LoginComponent } from './shared/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SignUpComponent } from './shared/sign-up/sign-up.component';
import { AddPetComponent } from './shared/add-pet/add-pet.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PetsCategoryComponent } from './shared/pets-category/pets-category.component';
import { AddAddressComponent } from './shared/add-address/add-address.component';
import { EditAddressComponent } from './shared/edit-address/edit-address.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { ChooseAreaComponent } from './shared/choose-area/choose-area.component';
import { SplashScreenComponent } from './shared/splash-screen/splash-screen.component';

import { QRCodeModule } from 'angularx-qrcode';


export function createLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/Languages/', '.json');
}

@NgModule({
  declarations: [
    AppComponent, 
    LoginComponent, 
    SignUpComponent, 
    AddPetComponent, 
    PetsCategoryComponent, 
    AddAddressComponent, 
    EditAddressComponent,
    ForgotPasswordComponent,
    ChooseAreaComponent,
    SplashScreenComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    AngularFireAnalyticsModule,
    AngularFireRemoteConfigModule,
    ReactiveFormsModule,
    HttpClientModule,
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: DEFAULTS, useValue: { delivery: 'none' } },
    {
      provide: SETTINGS,
      useValue: { minimumFetchIntervalMillis: 1000 }
    } 
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
