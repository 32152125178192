import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  selected = '';

  constructor(public translate: TranslateService) {

    this.initLang();

  }

  initLang() {
    
    this.translate.setDefaultLang('en');

    this.selected = 'en';


    if (localStorage.getItem('language') == null) {      
      this.setLanguage('en');
    } else {
      this.setLanguage(localStorage.getItem('language'));
      this.selected = localStorage.getItem('language');
    }

  }
  

  setLanguage(lang: string) {
    this.translate.use(lang);
    this.selected = lang;
    this.translate.setDefaultLang(lang);
  }
  
}
