import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ToastController } from '@ionic/angular';
import { SoulsUser } from '../modals/users.modal';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private toastCtrl: ToastController,
    private cm: AngularFireMessaging,
    private afs: AngularFirestore,
  ) {     
    // this.cm.useServiceWorker()
    this.receiveMesseges();
  }

  async requestPermession(fcmTokens: string[], uid: string) {

    let token: string;

    try {

      await this.cm.requestPermission.toPromise();
      console.log('here');
      token = await this.cm.getToken.toPromise();

    } catch (e) {
      console.log(e);
      return;
    }


    let newTokens: string[] = [];

    if (token == null) {
      return;
    }

    if (fcmTokens == undefined) {
      newTokens.push(token);
    } else {
      if (fcmTokens == null) {
        newTokens.push(token)
      } else {

        const index = fcmTokens.findIndex(item => {
          return item == token;
        });

        if (index !== -1) {
          return;
        }

        newTokens = fcmTokens;
        newTokens.push(token);

      }
    }

    console.log(token);
    

    try {
      await this.afs.doc<SoulsUser>('clients/' + uid).update({
        fcmTokens:  newTokens
      });
    } catch (e) {
      console.log(e);
      return;
    }



  }

  receiveMesseges() {

    this.cm.onMessage((payload) => {
      
      this.toastCtrl.create({
        header: payload.notification.title,
        mode: 'ios',
        message: payload.notification.body,
        duration: 3000,
        color: 'dark',
        position: 'bottom'
      }).then(toastEl => {
        toastEl.present();
      });


    });

//     notification: 
//      body: "Hello World"
//      tag: "campaign_collapse_key_2474648506015930560"
//      title: "Test"

  }
  


}
