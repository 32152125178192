import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { CartItem, ShopItem, Address, AreaList, Order } from '../modals/shop.modal';
import { SoulsUser } from '../modals/users.modal';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  public ItemDetails: ShopItem;
  public cart$ = new BehaviorSubject<CartItem[]>([]);

  public categories$ = new BehaviorSubject<string[]>([]);

  public areas$ = new BehaviorSubject<AreaList[]>([]);

  public selectedHistory: Order;

  editedAddress: Address;

  constructor(
    private afs: AngularFirestore,
  ) { 
    this.fetchCategories();
    this.fetchAreas();
  }

  fetchAreas() {
    this.afs.doc('options/AreaLists').get().subscribe(list => {
      this.areas$.next(list.data().list);      
    });
  }

  fetchCategories() {

    this.afs.doc('options/shopCategories').get().subscribe(categories => {
      this.categories$.next(categories.data().categories);
    })

  }

  addItemToCart(item: ShopItem, user: SoulsUser, varientName?: string, count: number = 1) {

    const newCartItems = this.cart$.value;

    const index = newCartItems.findIndex(itemElement => {
      if (itemElement.item.id == item.id) {

        if (varientName == null) {
          return true;
        } else {
          return varientName == itemElement.varientValue
        }

        
      } else {
        return false;
      }

    });

    if (index == -1) {
      newCartItems.push({
        item: item,
        quantity: count,
        varientValue: varientName
      })
    } else {
      newCartItems[index].quantity = newCartItems[index].quantity + count;
    }
    
    this.cart$.next(newCartItems);

    if (!!user) {
      this.afs.doc('clients/' + user.uid).update({
        cart: newCartItems
      })
    }

  }

  deductItemToCart(item: ShopItem, user: SoulsUser, varientName?: string, count: number = 1) {

    const newCartItems = this.cart$.value;

    const index = newCartItems.findIndex(itemElement => {
      if (itemElement.item.id == item.id) {

        if (varientName == null) {
          return true;
        } else {
          return varientName == itemElement.varientValue
        }

        
      } else {
        return false;
      }

    });

    if (index == -1) {
      return;
    } else {
      if (newCartItems[index].quantity >= 2) {
        newCartItems[index].quantity = newCartItems[index].quantity - count;
      } else {
        return;
      }
    }
    
    this.cart$.next(newCartItems);

    if (!!user) {
      this.afs.doc('clients/' + user.uid).update({
        cart: newCartItems
      })
    }
    

  }

  deleteItemFromCart(item: ShopItem, user: SoulsUser, varientName?: string, count: number = 1) {

    const newCartItems = this.cart$.value;

    const index = newCartItems.findIndex(itemElement => {
      if (itemElement.item.id == item.id) {

        if (varientName == null) {
          return true;
        } else {
          return varientName == itemElement.varientValue
        }

        
      } else {
        return false;
      }

    });

    if (index == -1) {
      return;
    } else {
      if (newCartItems[index].quantity <= 1) {
        newCartItems.splice(index, 1);
      } else {
        return;
      }
    }
    
    this.cart$.next(newCartItems);

    if (!!user) {
      this.afs.doc('clients/' + user.uid).update({
        cart: newCartItems
      })
    }
    

  }




  
}
