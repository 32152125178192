import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { take } from 'rxjs/operators';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { SoulsUser } from 'src/app/modals/users.modal';
import { LoginService } from 'src/app/services/login.service';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {

  form: FormGroup;
  form2: FormGroup;


  isOTPMode: boolean = false;

  recaptcha: firebase.auth.RecaptchaVerifier;
  conformation: firebase.auth.ConfirmationResult;

  selectedCountryCode: any = '';

  constructor(
    private modalCtrl: ModalController,
    private afs: AngularFirestore,
    private auth: AngularFireAuth,
    private loadCtrl: LoadingController,
    private alertCtrl: AlertController,
    private nav: NavController,
    private loginService: LoginService,
    private shopService: ShopService
  ) { }


  ionViewDidEnter() {

    // this.recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //   'size': 'invisible'
    // });

  }

  ngOnInit() {

    this.form = new FormGroup({
      name: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      }),
      email: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.email]
      }),
      password: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required, Validators.maxLength(25), Validators.minLength(6)]
      }),
      birthday: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      }),
      civilId: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      }),
      mobile: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      }),
      country: new FormControl(null, {
        updateOn: "change",
        validators: [Validators.required]
      })
    });

    this.form.addControl('retypePassword', new FormControl(
      '', [Validators.compose(
          [Validators.required, this.validateAreEqual.bind(this)]
      )]
    ));

  }

  private validateAreEqual(fieldControl: FormControl) {
    return fieldControl.value === this.form.get("password").value ? null : {
        NotEqual: true
    };
  }

  onClose() {
    this.modalCtrl.getTop().then(modalEl => {
      modalEl.dismiss();
    });
  }

  async signUp() {
      
    const LoadEl = await this.loadCtrl.create();
    LoadEl.present();

    try {

      const data = await this.afs.collection('clients', ref => ref
      .where('mobile', '==', '+' + this.form.value.country + this.form.value.mobile))
      .valueChanges().pipe(take(1)).toPromise();

      if (data.length > 0) {

        const alertEl = await this.alertCtrl.create({
          cssClass: 'alert-main',
          mode: 'ios',
          header: 'Error',
          message: "The Number Exists",
          buttons: [{text: 'OK'}]
        });
        alertEl.present();
        LoadEl.dismiss();
        return;
      }
    } catch (e) {

      console.log(e);
      

      const alertEl = await this.alertCtrl.create({
        cssClass: 'alert-main',
        mode: 'ios',
        header: 'Error',
        message: "Error",
        buttons: [{text: 'OK'}]
      });
      alertEl.present();
      LoadEl.dismiss();
      return;
    }

    try {

      const data = await this.afs.collection('clients', ref => ref
      .where('email', '==', this.form.value.email))
      .valueChanges().pipe(take(1)).toPromise();

      if (data.length > 0) {

        const alertEl = await this.alertCtrl.create({
          cssClass: 'alert-main',
          mode: 'ios',
          header: 'Error',
          message: "The Email Exists",
          buttons: [{text: 'OK'}]
        });
        alertEl.present();
        LoadEl.dismiss();
        return;
      }
    } catch (e) {

      console.log(e);
      

      const alertEl = await this.alertCtrl.create({
        cssClass: 'alert-main',
        mode: 'ios',
        header: 'Error',
        message: JSON.stringify(e),
        buttons: [{text: 'OK'}]
      });
      alertEl.present();
      LoadEl.dismiss();
      return;
    }

    const newUser: SoulsUser = {...this.form.value};

    this.auth.createUserWithEmailAndPassword(newUser.email, newUser.password).then(async credentials => {
      

      newUser.mobile = '+' + this.form.value.country + newUser.mobile;

      delete newUser['country'];
      delete newUser['password'];
      delete newUser['retypePassword'];

      newUser.birthday = newUser.birthday.split('T')[0];
      newUser.address = [];
      newUser.cart = this.shopService.cart$.value;

      try {
        await this.afs.doc<SoulsUser>('clients/' + credentials.user.uid).set(newUser);
        newUser.uid = credentials.user.uid;
        this.loginService.loginUser = newUser;
        LoadEl.dismiss();
        this.modalCtrl.getTop().then(modalEl => {
          modalEl.dismiss();
          this.nav.navigateRoot('/my-profile');
        });

      } catch (e) {

        console.log(e);
        alert('error - creating database');
        LoadEl.dismiss();

      }



    })
    .catch(e => {

      this.afs.collection('error').add(e);

      if ('message' in e) {
        alert(e.message);
      } else {
        alert('error: ' + JSON.stringify(e));
        console.log(e);
      }

      LoadEl.dismiss();
      
    });

  }

}
